/* ----------Navbar----------- */

ul.sidenav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  overflow: auto;
}

ul.sidenav li a {
  display: block;
  color: #000;
  padding: 30px 16px;
  text-decoration: none;
  transition: background-color .3s, color .3s;
}

ul.sidenav li a:hover:not(.active) {
  background-color: #555;
  color: white;
}

ul.sidenav span {
  font-size: 1rem;
}

ul.sidenav img {
  width:88px;
}

/* ------------General Rules------------ */

div.content {
  padding: .1px 0;
  width: 80vw;
  margin: 0 auto;
}

.landing {
  text-align: center;
}

section {
  margin: 70px 0
}

h2 {
  text-decoration: underline;
  margin: 30px
}

/* ----------Header-------------- */

#header h1 {
  margin: 5%;
  font-size: 3rem;
}

#header span {
  color: white;
}

/* ---------------Skills---------------- */

.skill-options {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.skill-options h2 {
  width: fit-content;
  padding: 8px 0;
  margin: 0 auto;
  text-decoration: underline;
}

.skill-section,
.project {
  background-color: white;
  padding: 10px 30px;
  border-radius: 15px;
  text-align: center;
  margin: 10px 0;
}

.skill-section ul {
  padding: 0;
  margin: 0 auto;
}

.skill-section li {
  list-style-type: none;
  width: fit-content;
  margin: 0 auto;
}

/* -----------Projects----------- */

.top-projects {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.project {
  padding: 0;
  padding-bottom: 30px;
  width: 50vw;
  margin: 30px auto;
  transition: width .3s;
}

.project:hover {
  width: 60vw
}

.project p {
  width: 80%;
  margin: 10px auto;
  font-size: 1.3rem;
}

.project img {
  width: 100%;
  margin: 0 auto;
  border-radius: 15px 15px 0 0;
}

.project h3 {
  text-decoration: underline;
}

.project a {
  text-decoration: none;
  margin: 0 5%;
  font-weight: bold;
}

.project a:hover {
  text-decoration: underline;
}

/* ------------About----------- */

#about strong {
  font-size: 1.6rem;
}

/* -----------------Contact-------------- */

.contact {
  margin-bottom: 5%;
}

.links ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: fit-content;
  list-style-type: none;
}

.links img {
  width: 42px;
  transition: .3s;
}

.links img:hover {
  width: 54px
}

.links a {
  text-decoration: none;
  color: black;
}

.links p {
  font-weight: bold;
  text-decoration: underline;
}

/* -------------Media Queries------------- */

@media screen and (max-width: 900px) {
  ul.sidenav {
    width: 120%;
    height: auto;
    position: relative;

  }

  ul.sidenav li {
    float: left;
    padding: 15px 4%;
  }

  .project {
    width: 80vw;
  }

  .project:hover{
    width: 80vw;
  }
}



@media screen and (max-width: 550px) {
  ul.sidenav li a {
    float: left;
    padding: 5px 4%;
  }

  .links ul {
    flex-direction: column;
  }

  .skill-options {
    flex-direction: column;
    justify-content: space-around;
  }
}